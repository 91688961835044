import styled from '@emotion/styled'
import IconBackwardRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconBackwardRegular'
import IconRemoveCircleFill from '@karrotmarket/karrot-ui-icon/lib/react/IconRemoveCircleFill'
import IconSearchThin from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchThin'
import { vars } from '@seed-design/design-token'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'

import { TextInput } from '.'

interface Props {
  placeholder?: string
  onChange: (searchText: string) => void
  onSearch: ({ searchText }: { searchText: string }) => Promise<void>
  onBack: () => void
}

const SearchBar = ({ placeholder, onChange, onSearch, onBack }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setTimeout(() => {
      if (!inputRef.current) return
      inputRef.current.focus()
    }, 300)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchNavbar>
      <StyledHeader>
        <StyledNavbarBack
          onClick={() => {
            onBack()
          }}>
          <IconBackwardRegular className="icon" />
        </StyledNavbarBack>
        <StyledSearchBar>
          <IconSearchThin className="icon position-left" />
          <form
            onSubmit={(e: FormEvent) => {
              e.preventDefault()
              onSearch({ searchText })
              setTimeout(() => {
                inputRef.current?.blur()
              }, 0)
            }}>
            <div>
              <TextInput
                id="poi-input"
                value={searchText}
                ref={inputRef}
                placeholder={placeholder}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchText(e.target.value)
                  onChange(e.target.value)
                }}
              />
            </div>
          </form>
          {searchText && (
            <IconRemoveCircleFill
              className="icon position-right"
              onClick={() => {
                onChange('')
              }}
            />
          )}
        </StyledSearchBar>
      </StyledHeader>
    </SearchNavbar>
  )
}

const SearchNavbar = styled.div`
  position: relative;
`
const StyledNavbarBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  width: 43px;
  height: 43px;
  .icon {
    width: 24px;
    height: 24px;
  }
`

const StyledHeader = styled.div`
  position: relative;
  padding: 8px 8px 8px 0;
  display: flex;
  align-items: center;
`

const StyledSearchBar = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 8px 0 0;
  form {
    flex: 1;
  }
  input {
    background-color: ${vars.$scale.color.gray100};
    border: none;
    margin: 0;
    padding: 10px 16px 10px 40px;
    height: 40px;
    width: 100%;
    &:focus {
      border: none;
    }
  }
  .icon {
    width: 18px;
    height: 18px;
    color: ${vars.$scale.color.gray600};
    &.position-left {
      position: absolute;
      top: 10px;
      left: 12px;
    }
    &.position-right {
      position: absolute;
      top: 11px;
      right: 12px;
    }
  }
`

export default SearchBar
